import { useQuery } from 'react-query';

import apiClient from '../../../services/api';
import { FetchResult } from './types';

export const getSkFlights = async (): Promise<FetchResult[]> => {
    const response = await apiClient.get<FetchResult[]>('/api/sk-flights');

    return response.data;
};

export function useSkFlights() {
    return useQuery('sk-flights', () => getSkFlights(), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
        onError: error => console.log(error),
    });
}
