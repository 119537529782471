import apiClient from '../../../services/api';
import { FetchResult } from './types';

interface Variables {
    ticketData: FetchResult;
    status: string;
    ticketNumber?: string;
    ticketPrice?: string;
    refundable?: boolean;
    comment?: string;
}

const updateTicket = async (variables: Variables) => {
    const { ticketData, ticketNumber, ticketPrice, refundable = false, comment = '', ...rest } = variables;

    const { data: response } = await apiClient.post<FetchResult>('api/update-ticket', {
        ...ticketData,
        ticket_number: ticketNumber,
        price: ticketPrice,
        refundable,
        comment,
        ...rest,
    });

    return response;
};

export default updateTicket;
