/* eslint-disable no-useless-escape */
/* eslint-disable prefer-named-capture-group */
export const IS_EMAIL =
    /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

// at least one lowercase letter, one uppercase letter, one digit, one special character, and is at least eight characters.
export const IS_PASSWORD = /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,}))/;

// decimal with two digits
export const IS_DECIMAL_TWO_DIGITS = /^[0-9]*(\.[0-9]{0,2})?$/;
