import { DataGrid } from '@get-e/react-components';
import { GridRowId } from '@mui/x-data-grid';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { FC, useEffect, useMemo, useState } from 'react';
import { useToCancel } from './api/useToCancel';
import { getColumnFlights, mapFlightsRow } from './data';
import { useDataGridStyles } from './styles/useDataGridStyles';
import UpdateModal from './UpdateModal';

interface ToCancelProps {
    onUpdate: (toCancel: number) => void;
    active: boolean;
}

const initialState: GridInitialStateCommunity = {
    sorting: {
        sortModel: [{ field: 'date', sort: 'asc' }],
    },
    filter: {
        filterModel: {
            items: [
                {
                    columnField: 'airline',
                    operatorValue: 'is',
                },
            ],
        },
    },
};

const ToCancelPanel: FC<ToCancelProps> = ({ onUpdate, active }) => {
    const { data: toCancelData = [], isFetching: isFetchingToCancel } = useToCancel();
    const dataGridClasses = useDataGridStyles();
    const [openModal, setOpenModal] = useState<GridRowId | null>(null);

    const handleCloseModal = () => setOpenModal(null);

    const ticketData = useMemo(() => {
        if (toCancelData) {
            return toCancelData?.find(ticket => ticket.id === openModal);
        }
    }, [openModal, toCancelData]);

    useEffect(() => {
        onUpdate?.(toCancelData?.length ?? 0);
    }, [toCancelData?.length, onUpdate]);

    const rows = toCancelData.length > 0 ? mapFlightsRow(toCancelData) : [];

    return (
        <div className={dataGridClasses.dataGrid}>
            {active && (
                <DataGrid
                    enableFilters
                    loading={isFetchingToCancel}
                    rows={rows}
                    columns={getColumnFlights(toCancelData)}
                    pageSize={20}
                    rowsPerPageOption={[20]}
                    autoHeight
                    header="Flights to cancel"
                    onRowClick={row => setOpenModal(row.id)}
                    noRows="No flights to cancel"
                    initialState={initialState}
                />
            )}
            {openModal && <UpdateModal open={openModal} onClose={handleCloseModal} ticketData={ticketData} />}
        </div>
    );
};

export default ToCancelPanel;
