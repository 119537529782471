import { Modal, PrimaryButton, SecondaryButton, Heading, TextField, Select, SelectOption } from '@get-e/react-components';
import CheckIcon from '@mui/icons-material/Check';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { Checkbox, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GridRowId } from '@mui/x-data-grid';
import copy from 'copy-to-clipboard';
import { format } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import { FC, ReactNode, useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';

import { useNotificationContext } from '../../context/NotificationContext';
import getValue from '../../helpers/getValue';
import { IS_DECIMAL_TWO_DIGITS } from '../../helpers/validation/regexRules';
import { FetchResult } from './api/types';
import updateTicket from './api/updateTicket';

const useStyles = makeStyles(() => ({
    modal: { minWidth: '1000px' },
    dataRow: { marginBottom: '16px' },
    label: { width: '40%', color: '#788B9B' },
    gridContainer: { display: 'flex' },
    gridColumn: { display: 'flex', flexDirection: 'column', width: '50%' },
    select: { width: '100%', minWidth: '197px' },
    copy: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        transition: '150ms all ease-in-out',
        '&:hover': {
            opacity: '0.7',
        },
    },
    copyIcon: { marginLeft: '16px', color: '#788B9B' },
}));

interface UpdateModalProps {
    onClose: () => void;
    onUpdate?: () => void;
    open: GridRowId | null;
    ticketData?: FetchResult;
}

const UpdateModal: FC<UpdateModalProps> = ({ onClose, onUpdate, open, ticketData }) => {
    const classes = useStyles();
    const queryClient = useQueryClient();
    const [ticketNumber, setTicketNumber] = useState(ticketData?.ticket_number ?? '');
    const [ticketPrice, setTicketPrice] = useState(ticketData?.price);
    const [refundable, setRefundable] = useState(Boolean(ticketData?.refundable));
    const [status, setStatus] = useState(ticketData?.status ?? 'NOT_BOOKED');
    const [comment, setComment] = useState(ticketData?.comment ?? '');
    const [ticketPriceError, setTicketPriceError] = useState('');
    const { displayLogs } = useFlags();

    const { mutate: updateTicketMutation, isLoading: isUpdatingTickets } = useMutation(updateTicket, {
        onSuccess: () => {
            if (displayLogs) {
                console.log('DEBUG [UpdateModal::updateTicketMutation::onSuccess] Before onUpdate');
            }

            onUpdate?.();
            queryClient.invalidateQueries('to-book-flights');
            queryClient.invalidateQueries('to-cancel-flights');
            queryClient.invalidateQueries('processed-flights');
            onClose();

            if (displayLogs) {
                console.log('DEBUG [UpdateModal::updateTicketMutation::onSuccess] After onClose');
            }
        },
        onError: (error: Error) => {
            console.log(error);
        },
    });

    const handleSubmit = () => {
        if (status === 'BOOKED' && !ticketPrice) {
            setTicketPriceError('Required');
            return;
        }

        updateTicketMutation(postData);
    };

    const handleOnClose = () => {
        setStatus('');
        setComment('');
        setTicketNumber('');
        setRefundable(false);
        onClose();
    };

    if (!ticketData) {
        return null;
    }

    const disableSave = getValue(() => {
        if (ticketData.status === 'NOT_BOOKED' && status === 'NOT_BOOKED') {
            return true;
        }

        if (status === 'BOOKED') {
            return ticketNumber.length === 0;
        }

        if (ticketData.status === 'CANCELLED') {
            return true;
        }

        return false;
    });

    const disableInputs = getValue(() => {
        if (ticketData.status === 'NOT_BOOKED' && status === 'NOT_BOOKED') {
            return true;
        }

        if (ticketData.status === 'CANCELLED') {
            return true;
        }

        return false;
    });

    const postData = getValue(() => {
        return {
            ticketData,
            comment,
            status,
            ticketNumber,
            ticketPrice,
            refundable,
        };
    });

    const getInputHelperText = getValue(() => {
        if (status === 'BOOKED') {
            return 'Ticket number is required';
        }
    });

    const getSelectHelperText = getValue(() => {
        if (ticketData.status === 'NOT_BOOKED' && status === 'NOT_BOOKED') {
            return 'Update status to save changes';
        }

        if (ticketData.status === 'CANCELLED') {
            return "Cancelled tickets can't be updated";
        }
    });

    const checkDecimalValue = (value: string) => {
        if (IS_DECIMAL_TWO_DIGITS.test(value)) {
            setTicketPrice(value);
            setTicketPriceError('');
        }
    };

    return (
        <Modal open={open !== null} onClose={handleOnClose} maxWidth="xl">
            <div className={classes.modal}>
                <div>
                    <Heading level={2}>
                        {ticketData.flight.departure} to {ticketData.flight.arrival}
                    </Heading>
                </div>
                <div className={classes.gridContainer}>
                    <div className={classes.gridColumn}>
                        <DataRow label="Date">{format(moment(ticketData.flight.start_time).valueOf(), 'dd MMM yyyy')}</DataRow>
                        <DataRow label="Flight">
                            {ticketData.flight.airline} {ticketData.flight.number}
                        </DataRow>
                        <DataRow label="Departure">{ticketData.flight.departure}</DataRow>
                        <DataRow label="Arrival">{ticketData.flight.arrival}</DataRow>
                        <DataRow label="Duty designator">{ticketData.flight.duty_designation}</DataRow>
                        <DataRow label="Status">
                            <div className={classes.select}>
                                <Select
                                    helperText={getSelectHelperText}
                                    label="Status"
                                    value={status ?? ''}
                                    onChange={newStatus => {
                                        setStatus(newStatus);
                                    }}
                                >
                                    <SelectOption disabled={ticketData.status !== 'NOT_BOOKED'} value="IGNORED">
                                        Ignore
                                    </SelectOption>
                                    <SelectOption value="NOT_BOOKED">Not created</SelectOption>
                                    <SelectOption value="BOOKED">Booked</SelectOption>
                                    <SelectOption value="CANCELLED" disabled={ticketData.status !== 'BOOKED'}>
                                        Cancelled
                                    </SelectOption>
                                </Select>
                            </div>
                        </DataRow>
                        <DataRow label="Ticket number">
                            <TextField
                                disabled={disableInputs}
                                label="e.g. K3RLW4J"
                                value={ticketNumber}
                                helperText={getInputHelperText}
                                onChange={e => setTicketNumber(e.target.value)}
                            />
                        </DataRow>
                        <DataRow label="Ticket price (EUR)">
                            <TextField
                                disabled={disableInputs}
                                label="e.g. 88.44"
                                value={ticketPrice}
                                onChange={e => checkDecimalValue(e.target.value)}
                                helperText={ticketPriceError}
                                error={Boolean(ticketPriceError)}
                                required
                            />
                        </DataRow>
                        <DataRow label="Refundable">
                            <Checkbox disabled={disableInputs} onClick={() => setRefundable(!refundable)} checked={refundable} />
                        </DataRow>
                        <DataRow label="Comments">
                            <TextField
                                disabled={disableInputs}
                                label="Add a comment"
                                value={comment}
                                onChange={e => setComment(e.target.value)}
                                minRows={4}
                                multiline
                            />
                        </DataRow>
                    </div>
                    <div className={classes.gridColumn}>
                        <DataRow label="First name">
                            <CopyData message={ticketData.employee.first_name} />
                        </DataRow>
                        <DataRow label="Last name">
                            <CopyData message={ticketData.employee.last_name} />
                        </DataRow>
                        <DataRow label="Sex">
                            <CopyData message={ticketData.employee.sex} />
                        </DataRow>
                        <DataRow label="Date of birth (dd.mm.yyyy)">
                            <CopyData message={format(moment(ticketData.employee.date_of_birth).valueOf(), 'dd.MM.yyyy')} />
                        </DataRow>
                        <DataRow label="Email">
                            <CopyData message={ticketData.employee.email_address} />
                        </DataRow>
                        <DataRow label="Mobile">
                            <CopyData message={ticketData.employee.phone_number} />
                        </DataRow>
                        <DataRow label="Crew code">
                            <CopyData message={ticketData.employee.number.toString()} />
                        </DataRow>
                        <DataRow label="Employment date">
                            <CopyData message={format(moment(ticketData.employee.date_of_employment).valueOf(), 'dd.MM.yyyy')} />
                        </DataRow>
                    </div>
                </div>
                <Grid container spacing={2}>
                    <Grid item>
                        <PrimaryButton disabled={disableSave} onClick={handleSubmit} loading={isUpdatingTickets}>
                            Save
                        </PrimaryButton>
                    </Grid>
                    <Grid item>
                        <SecondaryButton disabled={isUpdatingTickets} onClick={handleOnClose}>
                            Close
                        </SecondaryButton>
                    </Grid>
                </Grid>
            </div>
        </Modal>
    );
};

export default UpdateModal;

const DataRow: FC<{ label: string; children: ReactNode }> = ({ label, children }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.dataRow}>
            <Grid item className={classes.label}>
                {label}
            </Grid>
            <Grid>{children}</Grid>
        </Grid>
    );
};

const CopyData: FC<{ message: string }> = ({ message }) => {
    const classes = useStyles();
    const [copied, setCopied] = useState(false);
    const { showNotification } = useNotificationContext();

    if (!message?.length) {
        return null;
    }

    return (
        <span
            className={classes.copy}
            onClick={() => {
                copy(message);
                setCopied(true);
                showNotification('Copied text');
            }}
        >
            {message}
            {copied ? (
                <CheckIcon fontSize="small" className={classes.copyIcon} />
            ) : (
                <FileCopyOutlinedIcon fontSize="small" className={classes.copyIcon} />
            )}
        </span>
    );
};
