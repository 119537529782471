import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { format } from 'date-fns';
import moment from 'moment';

import { FetchResult, FilghtToDisplay } from './api/types';

export const getColumns = (rows: FetchResult[]): GridColDef[] => {
    const uniques = getUniqueAirlines(rows);

    return [
        {
            field: 'date',
            headerName: 'Date',
            minWidth: 130,
            valueFormatter: params => format(params.value, 'dd MMM yyyy'),
        },
        {
            field: 'route',
            headerName: 'Route',
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<string>) => (
                <div className="dataGridRoute">
                    {params?.row?.route}
                    <span className="dataGridTime">
                        {params?.row?.start_time}-{params?.row?.end_time}
                    </span>
                </div>
            ),
        },
        {
            field: 'airline',
            headerName: 'Airline',
            minWidth: 60,
            type: 'singleSelect',
            valueOptions: () => uniques ?? [],
        },
        {
            field: 'flight',
            headerName: 'Flight',
            minWidth: 80,
        },
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 230,
            flex: 1,
        },
        {
            field: 'duty_des',
            headerName: 'Duty Des.',
            minWidth: 100,
        },
    ];
};

export const getUniqueAirlines = (toBookData: FetchResult[]) => {
    const airlines = toBookData.map((fl: FetchResult) => {
        return fl.flight.airline;
    });

    return airlines.filter((v, i, a) => a.indexOf(v) === i && v !== '');
};

export const mapToRow = (rows: FetchResult[]): FilghtToDisplay[] => {
    return rows.map(row => {
        return {
            id: row.hash,
            date: moment(row.flight.start_time).valueOf(),
            route: `${row.flight.departure} - ${row.flight.arrival}`,
            airline: row.flight.airline,
            flight: row.flight.number,
            name: `${row.employee.first_name} ${row.employee.last_name}`,
            duty_des: row.flight.duty_designation,
            start_time: format(moment(row.flight.start_time).valueOf(), 'HH:mm'),
            end_time: format(moment(row.flight.end_time).valueOf(), 'HH:mm'),
            valueOptions: ['sk', 'mk'],
        };
    });
};
