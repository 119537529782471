import { Alert, Form, PrimaryButton, SignedOutLayout, TertiaryButtonLink, TextField } from '@get-e/react-components';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { FC, useEffect, useRef, useState, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { logAmplitudeEvent } from '../amplitude/amplitude';
import { useAuth } from '../context/AuthenticatedUserContext';
import FormError from '../helpers/validation/FormError';
import getFormErrorMessage from '../helpers/validation/getFormErrorMessage';
import getHelperText from '../helpers/validation/getHelperText';
import InputError from '../helpers/validation/InputError';

const useStyles = makeStyles({
    container: {
        padding: '2em',
        maxWidth: '320px',
        margin: '0 auto',
    },
    heading: { marginBottom: '1rem' },
    forgotPasswordContainer: {
        marginTop: '1rem',
        textAlign: 'center',
    },
    forgotPasswordLink: {
        textDecoration: 'none',
        color: '#828282',
        fontWeight: 600,
    },
    signInButtonContainer: { marginTop: '1rem' },
});

const SignIn: FC<PropsWithChildren> = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { onLogin } = useAuth();

    const autoFocusRef = useRef<HTMLInputElement>();

    useEffect(() => {
        autoFocusRef.current?.focus();
    }, [autoFocusRef]);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState<InputError | null>(null);

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState<InputError | null>(null);

    const [showingPassword, setShowingPassword] = useState(false);
    const [signingIn, setSigningIn] = useState(false);

    const [formError, setFormError] = useState<FormError | 'INVALID_CREDENTIALS' | null>(null);

    async function submitForm(): Promise<void> {
        setSigningIn(true);

        try {
            await onLogin(email, password);
            logAmplitudeEvent('<SCREEN> Sign In', {
                'User email': email,
            });
            navigate('/flights/to-book');
        } catch (error) {
            setSigningIn(false);

            const isLoginError = axios.isAxiosError(error) && error.response?.status === 422;

            if (isLoginError) {
                setFormError('INVALID_CREDENTIALS');
            } else {
                setFormError(FormError.UnexpectedError);
            }
        }
    }

    const showPasswordButton = (
        <InputAdornment position="end">
            <IconButton
                aria-label={showingPassword ? 'Hide password' : 'Show password'}
                onClick={() => setShowingPassword(!showingPassword)}
                onMouseDown={event => event.preventDefault()}
            >
                {showingPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
        </InputAdornment>
    );

    return (
        <SignedOutLayout>
            <Paper elevation={0} className={classes.container}>
                <Form onSubmit={submitForm}>
                    <Typography variant="h2" component="h2" className={classes.heading}>
                        Positioning flights
                    </Typography>

                    <TextField
                        value={email}
                        onChange={event => {
                            setEmail(event.target.value);
                            setEmailError(null);
                        }}
                        label="Email"
                        type="email"
                        autoFocus
                        autoComplete="username"
                        name="email"
                        inputRef={autoFocusRef}
                        error={emailError !== null}
                        helperText={getHelperText(emailError)}
                        required
                    />

                    <TextField
                        label="Password"
                        type={showingPassword ? 'text' : 'password'}
                        value={password}
                        onChange={event => {
                            setPassword(event.target.value);
                            setPasswordError(null);
                        }}
                        autoComplete="current-password"
                        InputProps={{ endAdornment: showPasswordButton }}
                        error={passwordError !== null}
                        helperText={getHelperText(passwordError)}
                        required
                    />

                    {formError ? (
                        <Alert severity="error">
                            {formError === 'INVALID_CREDENTIALS'
                                ? 'Incorrect email or password. Please try again.'
                                : getFormErrorMessage(formError)}
                        </Alert>
                    ) : null}

                    <div className={classes.signInButtonContainer}>
                        <PrimaryButton onClick={() => submitForm()} loading={signingIn} fullWidth submitsForm>
                            Sign in
                        </PrimaryButton>
                    </div>

                    <div className={classes.forgotPasswordContainer}>
                        <TertiaryButtonLink to="/forgot-password">Forgot password?</TertiaryButtonLink>
                    </div>
                </Form>
            </Paper>
        </SignedOutLayout>
    );
};

export default SignIn;
