import { ComponentsProvider, FullScreenLoader, Notification, NotificationSeverity } from '@get-e/react-components';
import { Theme } from '@mui/material/styles';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { useState, FunctionComponent } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import { initAmplitude } from './amplitude/amplitude';
import CenterContainer from './components/CenterContainer';
import Retry from './components/Retry';
import { AuthProvider } from './context/AuthenticatedUserContext';
import { LocaleContext } from './context/LocaleContext';
import { NotificationContext, Severity } from './context/NotificationContext';
import ErrorBoundary from './ErrorBoundary';
import useEffectAsync from './helpers/useEffectAsync';
import useLoaderDebounce from './helpers/useLoaderDebounce';
import { setLocale as setI18nLocale } from './i18n';
import Routes from './Routes';

declare module '@mui/styles' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-empty-object-type
    interface DefaultTheme extends Theme {}
}

const App: FunctionComponent = () => {
    const [localeLoaded, setLocaleLoaded] = useState(false);

    const [notification, setNotification] = useState<{
        message: string;
        color: NotificationSeverity;
    } | null>(null);

    useEffectAsync(async () => {
        await setI18nLocale('en-GB');
        setLocaleLoaded(true);
    }, []);

    const showLoader = useLoaderDebounce(!localeLoaded);

    if (!localeLoaded) {
        return showLoader ? <FullScreenLoader /> : null;
    }

    const showNotification = (message: string, severity?: Severity): void => {
        const color = (() => {
            switch (severity) {
                case Severity.Info:
                case undefined:
                    return 'info';
                case Severity.Error:
                    return 'error';
                default:
                    throw new Error('Unsupported severity');
            }
        })();

        setNotification({
            message,
            color,
        });
    };

    const notificationElement = (() => {
        if (notification === null) {
            return null;
        }

        return (
            <Notification severity={notification.color} onClose={() => setNotification(null)}>
                {notification.message}
            </Notification>
        );
    })();

    const queryClient = new QueryClient();

    initAmplitude();

    return (
        <ErrorBoundary
            error={
                <CenterContainer>
                    <Retry onRetry={() => console.log('Retrying')} />
                </CenterContainer>
            }
        >
            <QueryClientProvider client={queryClient}>
                <LocaleContext.Provider value={{ locale: 'en-GB' }}>
                    <BrowserRouter>
                        <AuthProvider>
                            <ComponentsProvider>
                                <NotificationContext.Provider value={{ showNotification }}>
                                    {notificationElement}
                                    <Routes />
                                </NotificationContext.Provider>
                            </ComponentsProvider>
                        </AuthProvider>
                    </BrowserRouter>
                </LocaleContext.Provider>
            </QueryClientProvider>
        </ErrorBoundary>
    );
};

export default withLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID ?? '',
})(App);
