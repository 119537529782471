import { useQuery } from 'react-query';
import apiClient from '../../../services/api';
import { FetchResult } from './types';

export const getToBookFlights = async (): Promise<FetchResult[]> => {
    const response = await apiClient.get<FetchResult[]>('/api/flights-to-book');

    return response.data;
};

export function useToBook() {
    return useQuery('to-book-flights', () => getToBookFlights(), {
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        retry: 1,
        onError: error => console.log(error),
    });
}
