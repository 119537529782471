import { useQuery } from 'react-query';
import apiClient from '../../../services/api';
import { FetchResult } from './types';

const getToCancelFlights = async (): Promise<FetchResult[]> => {
    const response = await apiClient.get<FetchResult[]>('/api/tickets-to-cancel');

    return response.data;
};

export function useToCancel() {
    return useQuery(['to-cancel-flights'], () => getToCancelFlights(), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
        onError: error => console.log(error),
    });
}
