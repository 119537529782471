import { useQuery } from 'react-query';
import apiClient from '../../../services/api';
import { FetchResult } from './types';

const getProccessedFlights = async (start?: string, end?: string): Promise<FetchResult[]> => {
    const response = await apiClient.get<FetchResult[]>(`/api/processed-flights?start=${start ?? ''}&end=${end ?? ''}`);

    return response.data;
};

export function useProcessed(active: boolean, start?: string, end?: string) {
    return useQuery(['processed-flights'], () => getProccessedFlights(start, end), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
        enabled: active,
        onError: error => console.log(error),
    });
}
